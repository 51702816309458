var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.layoutEnabled || _vm.$route.name === 'societies-details')?_c('b-card',_vm._b({key:_vm.widgetKey,staticClass:"widget",class:( _obj = { stretch: _vm.stretch, 'disable-card': !_vm.enableCard }, _obj[("disable-card enable-card-" + _vm.enableCardSize)] = _vm.enableCardSize, _obj ),staticStyle:{"overflow":"auto"},attrs:{"body-class":[{ 'disable-card__card-body': !_vm.enableCard || _vm.enableCardSize }, _vm.bodyClass]}},'b-card',_vm.$attrs,false),[_vm._t("header",[_c('span',{staticClass:"d-flex justify-content-between"},[(_vm.title || _vm.appId)?_c('b-card-title',{class:_vm.titleClass},[_c('div',[_vm._t("title",[_vm._v(" "+_vm._s(_vm.computedTitle)+" ")],{"title":_vm.computedTitle})],2)]):_vm._e(),_c('div',{staticClass:"d-flex"},[(_vm.appId && _vm.isEditable)?_c('div',{staticClass:"p-50 zindex-4 settings mb-2 mt-n25 mr-1"},[_c('feather-icon',{staticClass:"text-primary pointer",attrs:{"icon":"Trash2Icon","size":"20","role":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.isUnshareModalVisible = true}}}),_c('feather-icon',{staticClass:"text-primary ml-1 pointer",attrs:{"icon":"Edit2Icon","size":"20","role":"button"},on:{"click":function($event){_vm.isEditingName=true}}}),_c('unshare-modal',{attrs:{"model-type":"addons"},on:{"remove-item":_vm.handleRemoveItem},model:{value:(_vm.isUnshareModalVisible),callback:function ($$v) {_vm.isUnshareModalVisible=$$v},expression:"isUnshareModalVisible"}})],1):_vm._e(),_vm._t("header-actions"),_vm._t("header-newactions")],2)],1)],null,{
      computedTitle: _vm.computedTitle,
      isEditable: _vm.isEditable,
      isEditingName: _vm.isEditingName,
      setIsEditingName: function (value) { return _vm.isEditingName = value; },
    }),_vm._t("default"),(_vm.appId)?_c('b-modal',{attrs:{"title":_vm.$t('layouts.edit-widget'),"ok-title":_vm.$t('form.actions.save')},on:{"ok":_vm.handleUpdatedTagline},model:{value:(_vm.isEditingName),callback:function ($$v) {_vm.isEditingName=$$v},expression:"isEditingName"}},[_vm._v(" "+_vm._s(_vm.$t('layouts.widget-name'))+" "),_c('form-translation-table',{staticClass:"mt-50 mb-3",attrs:{"value-placeholder":_vm.$t('layouts.widget-name'),"label-attribute":"name","value-attribute":"text"},on:{"input":_vm.handleTitleChange},model:{value:(_vm.computedAppNames),callback:function ($$v) {_vm.computedAppNames=$$v},expression:"computedAppNames"}}),_vm._v(" "+_vm._s(_vm.$t('layouts.widget-tagline'))+" "),_c('form-translation-table',{staticClass:"mt-50",attrs:{"id":"app-description","label-attribute":"description","value-attribute":"text","value-placeholder":_vm.$t('layouts.widget-tagline'),"name":_vm.$t('organizations.edit.description.name'),"placeholder":_vm.$t('organizations.edit.description.placeholder')},model:{value:(_vm.appDescription),callback:function ($$v) {_vm.appDescription=$$v},expression:"appDescription"}})],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }